<template>
<!--// Header \\-->
<header id="sportsmagazine-header" class="sportsmagazine-header-one">
            
  <!--// TopStrip \\-->
  <!-- <div class="sportsmagazine-topstrip">
      <div class="container">
          <div class="row">
              <aside class="col-md-4">
                  <ul class="sportsmagazine-social-network">
                      <li><a href="#" class="sportsmagazine-colorhover fa fa-facebook-official"></a></li>
                      <li><a href="#" class="sportsmagazine-colorhover fa fa-twitter-square"></a></li>
                      <li><a href="#" class="sportsmagazine-colorhover fa fa-linkedin-square"></a></li>
                      <li><a href="#" class="sportsmagazine-colorhover fa fa-google-plus-square"></a></li>
                  </ul>
              </aside>
              <aside class="col-md-8">
                  <ul class="sportsmagazine-user-section">
                      <li><i class="fa fa-globe"></i> <a href="#">Support</a></li>
                      <li><i class="fa fa-dollar"></i> <a href="#">Currency : USD</a></li>
                      <li><i class="fa fa-shopping-cart"></i> <a href="#" class="sportsmagazine-open-cart">Cart (0)</a>
                          <div class="sportsmagazine-cart-box">
                              <h2>You have 2 items in the cart</h2>
                              <ul>
                                  <li>
                                      <figure>
                                          <a href="#"><img src="extra-images/cartbox-1.png" alt=""></a>
                                      </figure>
                                      <div class="sportsmagazine-cartbox-text">
                                          <h6><a href="#">Key Management Model The 60+ Models</a></h6>
                                          <div class="sportsmagazine-rating"><span class="sportsmagazine-rating-box" style="width:80%"></span></div>
                                          <span class="sportsmagazine-cartbox-price sportsmagazine-color">$35.99 <small>$43.00</small></span>
                                      </div>
                                  </li>
                                  <li>
                                      <figure>
                                          <a href="#"><img src="extra-images/cartbox-2.png" alt=""></a>
                                      </figure>
                                      <div class="sportsmagazine-cartbox-text">
                                          <h6><a href="#">Pyramid Principle: Logic Writing &amp; Thinking</a></h6>
                                          <div class="sportsmagazine-rating"><span class="sportsmagazine-rating-box" style="width:100%"></span></div>
                                          <span class="sportsmagazine-cartbox-price sportsmagazine-color">$21.00</span>
                                      </div>
                                  </li>
                              </ul>
                              <h5>Subtotal <span class="sportsmagazine-color">$1343</span></h5>
                              <div class="sportsmagazine-cart-link"><a href="#" class="sportsmagazine-cartbox-btn sportsmagazine-bgcolorhover"><i class="flaticon-tool"></i> Go to Checkout</a></div>
                          </div>
                      </li>
                      <li><i class="fa fa-user"></i> <a href="#" data-toggle="modal" data-target="#loginModal">Login</a></li>
                      <li><i class="fa fa-sign-in"></i> <a href="#" data-toggle="modal" data-target="#signupModal">Sign Up</a></li>
                  </ul>
              </aside>
          </div>
      </div>
  </div> -->
  <!--// TopStrip \\-->

  <!--// MainSection \\-->
  <div class="sportsmagazine-main-header">
      <div class="container">
          <div class="row">
              <aside class="col-md-2"><a href="index.html" class="sportsmagazine-logo"><img src="../assets/images/logo-1.png" alt=""></a></aside>
              <div class="col-md-10">
                  <div class="sportsmagazine-left-section">
                      <nav class="navbar navbar-default" style="display:none;">
                          <div class="navbar-header">
                            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse-1" aria-expanded="true">
                              <span class="sr-only">Toggle navigation</span>
                              <span class="icon-bar"></span>
                              <span class="icon-bar"></span>
                              <span class="icon-bar"></span>
                            </button>
                          </div>
                          <div class="collapse navbar-collapse" id="navbar-collapse-1">
                            <ul class="nav navbar-nav">
                              <li class="active"><a href="#">Highlights</a></li>
                              <li><a href="#">Squad</a></li>
                              <li><a href="#">PLAYERS</a>
                                  <ul class="sportsmagazine-dropdown-menu">
                                      <li><a href="#">FollowedBreak53</a></li>
                                      <li><a href="#">Nicky Freakin J</a></li>
                                      <li><a href="#">thenateapproach</a></li>
                                      <li><a href="#">UniformLily6773</a></li>
                                  </ul>
                              </li>
                              <li><a href="#">NEWS</a>
                                  <!-- <ul class="sportsmagazine-dropdown-menu">
                                      <li><a href="#">Awards</a></li>
                                      <li><a href="#">Events</a></li>
                                      <li><a href="#">Treasury</a></li>
                                  </ul> -->
                              </li>
                              <!-- <li><a href="#">OUR SHOP</a>
                                  <ul class="sportsmagazine-dropdown-menu">
                                      <li><a href="shop-grid.html">Shop Grid</a>
                                          <ul class="sportsmagazine-dropdown-menu">
                                              <li><a href="shop-grid.html">Shop Grid W/O/S</a></li>
                                              <li><a href="shop-grid-wls.html">Shop Grid W/L/S</a></li>
                                              <li><a href="shop-grid-wrs.html">Shop Grid W/R/S</a></li>
                                          </ul>
                                      </li>
                                      <li><a href="shop-list.html">Shop List</a></li>
                                      <li><a href="shop-list-wls.html">Shop List W/L/S</a></li>
                                      <li><a href="shopping-cart.html">Shopping Cart</a></li>
                                      <li><a href="wishlist.html">WishList</a></li>
                                      <li><a href="account.html">Account</a></li>
                                      <li><a href="checkout.html">Checkout</a></li>
                                      <li><a href="shop-detail.html">Shop Detail</a>
                                          <ul class="sportsmagazine-dropdown-menu">
                                              <li><a href="shop-detail.html">Shop Detail W/R/S</a></li>
                                              <li><a href="shop-detail-wls.html">Shop Detail W/L/S</a></li>
                                          </ul>
                                      </li>
                                  </ul>
                              </li>
                              <li class="sportsmagazine-megamenu-li"><a href="#">Contact us</a>
                                  <ul class="sportsmagazine-megamenu">
                                      <li class="row">
                                          <div class="col-md-2">
                                              <h4>Links 1</h4>
                                              <ul class="sportsmagazine-megalist">
                                                  <li><a href="404.html">404 Error Page</a></li>
                                                  <li><a href="faq.html">Faq</a></li>
                                                  <li><a href="login-register.html">Login Register</a></li>
                                                  <li><a href="search-result.html">Search Result</a></li>
                                                  <li><a href="contact-us.html">Contact Us</a></li>
                                              </ul>
                                          </div>
                                          <div class="col-md-5">
                                              <h4>Player text</h4>
                                              <div class="sportsmagazine-mega-text">
                                                  <p>Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work. And the only way to do great work is to love.</p>
                                                  <p>If you haven't found it yet, keep looking. Don't settle. As with all matters of the heart, you'll know when you find it.</p>
                                              </div>
                                          </div>
                                          <div class="col-md-5">
                                              <h4>sub category widget</h4>
                                              <a href="#" class="sportsmagazine-thumbnail">
                                                  <img src="extra-images/mega-menuadd.jpg" alt="" />
                                              </a>
                                          </div>
                                      </li>
                                  </ul>
                              </li> -->
                            </ul>
                          </div>
                      </nav>
                      <!-- <a data-toggle="modal" data-target="#searchModal" href="#" class="sportsmagazine-search-btn sportsmagazine-colorhover"><i class="fa fa-search"></i></a> -->
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!--// MainSection \\-->

</header>
<!--// Header \\-->
</template>

<script>
export default {
  name: 'HeaderMain',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
