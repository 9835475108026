import { createApp } from 'vue'
import App from './App.vue'

// Global CSS Imports
import './assets/css/bootstrap.css';
import './assets/css/font-awesome.css';
import './assets/css/flaticon.css';
import './assets/css/slick-slider.css';
import './assets/css/fancybox.css';
import './assets/css/style.css';
import './assets/css/color.css';
import './assets/css/responsive.css';
import router from './router'

createApp(App).use(router).mount('#app')
