<template>
  <!--// Main Wrapper \\-->
  <div class="sportsmagazine-main-wrapper">
  <HeaderMain />

		<!--// Main Content \\-->
		<div class="sportsmagazine-main-content">
      
      <!-- ROUTER VIEW -->
      <router-view />
      
    </div>
    <!--// Main Content \\-->

  <FooterMain />
  <div class="clearfix"></div>
  </div>
</template>

<script>
import FooterMain from './components/FooterMain.vue'
import HeaderMain from './components/HeaderMain.vue'

export default {
  name: 'App',
  components: {
    FooterMain,
    HeaderMain,
  }
}
</script>

<style>

</style>
