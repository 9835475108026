<template>
  <!--// Main Section \\-->
  <div class="sportsmagazine-main-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="sportsmagazine-fancy-title">
            <h2>Career Highlights</h2>
            <!-- <h2>Recent Highlights</h2> -->
          </div>
          <div class="sportsmagazine-roster-graph sportsmagazine-overview-graph">
            <h4>SQUAD <span class="note">(TPP)</span></h4>
            <table 
              v-if="PUBGData.player1 && PUBGData.player2 && PUBGData.player3 && PUBGData.player4"
              class="sportsmagazine-client-detail thin-rows row-headings"
              >
              <tr class="heading-row">
                  <th>&nbsp;</th>
                  <th>FB</th>
                  <th>NFJ</th>
                  <th>TNA</th>
                  <th>UL</th>
              </tr>
              <!-- Stats Loop -->
              <tr v-for="(value, key) in PUBGData.player1.data.attributes.gameModeStats.squad" :key="key">
                <td><span>{{ formatStatName(key) }}</span></td> <!-- Property name -->
                <td>{{ Number(PUBGData.player1.data.attributes.gameModeStats.squad[key]).toFixed(0)}}</td>
                <td>{{ Number(PUBGData.player2.data.attributes.gameModeStats.squad[key]).toFixed(0)}}</td>
                <td>{{ Number(PUBGData.player3.data.attributes.gameModeStats.squad[key]).toFixed(0)}}</td>
                <td>{{ Number(PUBGData.player4.data.attributes.gameModeStats.squad[key]).toFixed(0)}}</td>
              </tr>
              <!-- End Stats Loop -->
            </table>
            <div class="clearfix"></div>
            <!-- <p class="text-center italics" style="padding-bottom:20px;">Key: Actual <span class="note">(Per Match)</span></p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--// Main Section \\-->
</template>

<script>
import { defineComponent } from 'vue';






export default defineComponent({
  name: 'HomeView',
  data() {
    return {
      PUBGData: {
        player1: null,
        player2: null,
        player3: null,
        player4: null,
      }
    }
  },
  mounted() {
    // API REQUESTS
    fetchPUBGData('players?filter[playerNames]=FollowedBreak53')
      .then(data => {
        const playerId = data.data[0].id;
        fetchPUBGData(`players/${playerId}/seasons/lifetime`)
          .then(data => {
            this.PUBGData.player1 = data;
          })
          .catch(error => console.error('Error:', error));
      })
      .catch(error => console.error('Error:', error));

    fetchPUBGData('players?filter[playerNames]=Nicky%20Freakin%20J')
      .then(data => {
        const playerId = data.data[0].id;
        fetchPUBGData(`players/${playerId}/seasons/lifetime`)
          .then(data => {
            this.PUBGData.player2 = data;
          })
          .catch(error => console.error('Error:', error));
      })
      .catch(error => console.error('Error:', error));

    fetchPUBGData('players?filter[playerNames]=thenateapproach')
      .then(data => {
        const playerId = data.data[0].id;
        fetchPUBGData(`players/${playerId}/seasons/lifetime`)
          .then(data => {
            this.PUBGData.player3 = data;
          })
          .catch(error => console.error('Error:', error));
      })
      .catch(error => console.error('Error:', error));

    fetchPUBGData('players?filter[playerNames]=UniformLily6773')
      .then(data => {
        const playerId = data.data[0].id;
        fetchPUBGData(`players/${playerId}/seasons/lifetime`)
          .then(data => {
            this.PUBGData.player4 = data;
          })
          .catch(error => console.error('Error:', error));
      })
      .catch(error => console.error('Error:', error));


    // FUNCTIONS
    function fetchPUBGData(endpoint) {
      const baseURL = "https://api.pubg.com/shards/xbox"; // Base URL for all API calls
      const requestURL = `${baseURL}/${endpoint}`;
      return fetch(requestURL, {
        method: 'GET',
        headers: {
          'Authorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJiZjViZWE5MC05OTMyLTAxM2MtNTIxOC01NjI2NGI2NmM3OTIiLCJpc3MiOiJnYW1lbG9ja2VyIiwiaWF0IjoxNzA1Njk0MTg0LCJwdWIiOiJibHVlaG9sZSIsInRpdGxlIjoicHViZyIsImFwcCI6InB1YmdzaWRlYnlzaWRlIn0.Z_VYVKMauFYOsO2LSjD_qneF6EhEly5fd9mVHKnpWco',
          'Accept': 'application/vnd.api+json'
        }
      }).then(response => response.json());
    }
  },
  methods: {
    formatStatName(propertyName) {
      // Add a space before each uppercase letter that follows a lowercase letter, except at the start of the string
      let formattedName = propertyName.replace(/(?<!^)([a-z])([A-Z])/g, '$1 $2').trim();
      // Then, capitalize the first letter of the resulting string
      return formattedName.charAt(0).toUpperCase() + formattedName.slice(1);
    }
  },
});


</script>
